const tabs = {
  init() {
    const $tabsParent = document.querySelector('.tabs');
    // const $tabsContent = $tabsParent.querySelectorAll('.tab-content');

    if ($tabsParent) {
      const $tabs = $tabsParent.querySelectorAll('.tab a');

      [].forEach.call($tabs, ($tab) => {
      // Array.from($tabs).forEach(($tab) => {
        $tab.addEventListener('click', (event) => {
          event.preventDefault();

          const href = $tab.getAttribute('href');

          $tabsParent.querySelector('.active').classList.remove('active');
          $tab.parentNode.classList.add('active');

          document.querySelector('.tab-content.active').classList.remove('active');
          document.querySelector(href).classList.add('active');

        }, false);
      });
    }

  },

  // eventListener($item, handler) {
  // $item.addEventListener('click', (event) => {
  // handler();
  // }, false);
  // },
};

export default tabs;
