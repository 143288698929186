import { __removeClass, __localScroll } from './libs/dom';
import tabs from './tabs';
import sidebar from './sidebar';

const domLoad = () => {

  // local page navigation
  const localNavLinks = document.querySelectorAll('[data-local-nav][href^="#"]');
  for (let i = 0; i < localNavLinks.length; i += 1) {
    localNavLinks[i].addEventListener('click', (event) => {
      __localScroll(event.target, localNavLinks[i].getAttribute('href'), 1200);
      event.preventDefault();
    });
  }

  // sidebar
  sidebar.init();

  // tabs
  tabs.init();
};


document.addEventListener('DOMContentLoaded', domLoad, false);

const winLoad = () => {
  // fully loaded
  const loading = document.querySelector('.loading');
  __removeClass(loading, 'loading');
};
// wait until all data are loaded into the DOM
window.addEventListener('load', winLoad, false);

