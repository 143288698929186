import { __addClass, __hasClass, __removeClass } from './libs/dom';

const sidebar = {
  init() {
    const $sidebar = document.querySelector('#sidebar');

    if ($sidebar) {
      const $sidebarToggles = document.querySelectorAll('[data-toggle-sidebar]');
      const $sidebarTogglesItem = document.querySelectorAll('[data-toggle-sidebar-content]');
      const $sidebarItems = $sidebar.querySelectorAll('.sidebar-item');
      const $sidebarExpandables = $sidebar.querySelectorAll('[data-expandable]');

      const sidebarState = sessionStorage.getItem('sidebar');
      const sidebarContentState = sessionStorage.getItem('sidebarContent');

      if (sidebarState === 'is-hidden') {
        __removeClass($sidebar, 'is-active');
      } else {
        __addClass($sidebar, 'is-active');
      }

      if (sidebarContentState) {
        for (let j = 0; j < $sidebarItems.length; j += 1) {
          __removeClass($sidebarItems[j], 'is-active');
        }
        for (let j = 0; j < $sidebarTogglesItem.length; j += 1) {
          __removeClass($sidebarTogglesItem[j], 'is-active');
        }

        __addClass($sidebar.querySelector(`.sidebar-item.sidebar-${sidebarContentState}`), 'is-active');
        __addClass($sidebar.querySelector(`[data-toggle-sidebar-content="${sidebarContentState}"]`), 'is-active');
      }

      // sidebar
      for (let i = 0; i < $sidebarToggles.length; i += 1) {
        $sidebarToggles[i].addEventListener('click', (event) => {

          if (__hasClass($sidebar, 'is-active')) {
            __removeClass($sidebar, 'is-active');
            sessionStorage.setItem('sidebar', 'is-hidden');
          } else {
            __addClass($sidebar, 'is-active');
            sessionStorage.setItem('sidebar', 'is-active');
          }

          event.preventDefault();
        });
      }

      // sidebar-items
      for (let i = 0; i < $sidebarTogglesItem.length; i += 1) {
        $sidebarTogglesItem[i].addEventListener('click', (event) => {

          const $toggle = $sidebarTogglesItem[i];

          if (!__hasClass($toggle, 'is-active')) {

            const target = $toggle.getAttribute('data-toggle-sidebar-content');
            const $item = document.querySelector(`.sidebar-item.sidebar-${target}`);

            for (let j = 0; j < $sidebarItems.length; j += 1) {
              __removeClass($sidebarItems[j], 'is-active');
            }
            for (let j = 0; j < $sidebarTogglesItem.length; j += 1) {
              __removeClass($sidebarTogglesItem[j], 'is-active');
            }

            __addClass($item, 'is-active');
            __addClass($toggle, 'is-active');

            sessionStorage.setItem('sidebarContent', target);
          }

          event.preventDefault();
        });
      }

      // sidebar-expandables
      for (let i = 0; i < $sidebarExpandables.length; i += 1) {
        $sidebarExpandables[i].addEventListener('click', (event) => {

          console.log(event.target);

          const $expandable = $sidebarExpandables[i].parentNode;

          if (__hasClass($expandable, 'is-active')) {
            __removeClass($expandable, 'is-active');
          } else {
            __addClass($expandable, 'is-active');
          }

          event.preventDefault();
        });
      }
    }
  },
};

export default sidebar;
