export function __hasClass(el, cls) {
  return (el.classList === 'object' && el.classList.contains === 'function') ? el.classList.contains(cls) : (`${el.className} `).indexOf(`${cls} `) > -1;
}

export function __addClass(elements, cls) {
  let _elements = elements;

  // if there are no elements, we're done
  if (!_elements) { return; }

  if (typeof (_elements) === 'string') {
    // if we have a selector, get the chosen _elements
    _elements = document.querySelectorAll(_elements);
  } else if (_elements.tagName) {
    // if we have a single DOM element, make it an array to simplify behavior
    _elements = [_elements];
  }

  // add class to all chosen _elements
  for (let i = 0; i < _elements.length; i += 1) {
    // check classList support
    if (_elements[i].classList) _elements[i].classList.add(cls);
    else if (!__hasClass(_elements[i], cls)) _elements[i].cls += ` ${cls}`;

  }
}

export function __removeClass(elements, cls) {
  let _elements = elements;

  // if there are no elements, we're done
  if (!_elements) { return; }

  if (typeof (_elements) === 'string') {
    // if we have a selector, get the chosen _elements
    _elements = document.querySelectorAll(_elements);
  } else if (_elements.tagName) {
    // if we have a single DOM element, make it an array to simplify behavior
    _elements = [_elements];
  }

  // remove class from all chosen _elements
  for (let i = 0; i < _elements.length; i += 1) {
    // check classList support
    if (_elements[i].classList) _elements[i].classList.remove(cls);
    else _elements[i].className = _elements[i].className.replace(new RegExp(`\\b${cls}\\b`, 'g'), '');

  }
}

export function __localScroll(anchor, destination, dur) {
  let duration = dur || 200;
  // smooth scroll function
  // https://jsfiddle.net/s61x7c4e/
  // Easing functions
  // https://gist.github.com/gre/1650294
  const easing = (t) => {
    return t < 0.5 ? 4 * t * t * t : ((t - 1) * ((2 * t) - 2) * ((2 * t) - 2)) + 1;
  };

  const y0 = window.pageYOffset;
  const y1 = document.querySelector(destination).offsetTop;

  // proportional scrolling
  if (y0 >= y1) {
    // scroll up
    if (duration < (y0 - y1)) {

    } else {
      duration = (y0 - y1) / 1.25;
    }
  } else {
    // scroll down
    if (duration < (y1 - y0)) {

    } else {
      duration = (y1 - y0) / 1.25;
    }
  }
  const diff = y1 - y0;

  let start;

  // Bootstrap our animation - it will get called right before next frame shall be rendered.
  window.requestAnimationFrame(function step(timestamp) {
    if (!start) start = timestamp;
    // Elapsed miliseconds since start of scrolling.
    const time = timestamp - start;
    // Get percent of completion in range [0, 1].
    // Apply the easing.
    // It can cause bad-looking slow frames in browser performance tool, so be careful.
    const percent = easing(Math.min(time / duration, 1));

    window.scrollTo(0, y0 + (diff * percent));

    // Proceed with animation as long as we wanted it to.
    if (time < duration) {
      window.requestAnimationFrame(step);
    }
  });
}

